<template src="./job_form.html"></template>
<script scoped>
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";
import $ from "jquery";
window.$ = $;
window.jQuery = $;

import "bootstrap/dist/js/bootstrap.bundle";
import "../../assets/styles/dropdown.scss";
import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);
import searchBG from "@/components/searchBG.vue"; // @ is an alias to /src
import EventService from "@/services/EventService.js";

const jobBanners = {
  "head-office": "/static/assets/jobs/ho_small.jpg",
  dc: "/static/assets/jobs/dc_small.jpg",
  store: "/static/assets/jobs/store_small.jpg"
};

export default {
  components: {
    searchBG
  },
  data() {
    return {
      mainImg: require(`@/assets/images/icon/Job_banner.png`),
      job: {},
      email: "",
      firstname: "",
      lastname: "",
      full_name: "",
      address: "",
      birth_date: "",
      current_salary: "",
      expected_salary: "",
      phone_number: "",
      description: "",
      resume: "",
      file_name: "",
      file: null,
      isLoading: true,
      filter: 0,
      dfilter: 0,
      success: false,
      selected: 1,
      listAction: [
        { name: "Clear All", code: 1 },
        { name: "Apply", code: 2 }
      ],
      carlendar: null,
      titleSelected: null,
      consent: "not_accepted",
      error: null,
      url: window.location.href,
      jobForm: null,
      fileInput: null,
      alert: {
        message: ""
      }
    };
  },
  computed: {
    getBanner() {
      const banner = jobBanners[this.job.area_slug];
      if (banner) return banner;
      return "/static/assets/jobs/default_small.jpg";
    },
    lang() {
      return this.$store.state.lang;
    },
    source() {
      return this.$store.getters.getSource["job-form"];
    },
    sourceDetail() {
      return this.$store.getters.getSource["job-detail"];
    },
    sourceLink() {
      return this.$store.getters.getSource["link"];
    },
    titleList() {
      if (this.lang === "EN") {
        return [
          { title: "Miss", code: 1 },
          { title: "Mrs.", code: 2 },
          { title: "Mr.", code: 3 }
        ];
      } else {
        return [
          { title: "นางสาว", code: 1 },
          { title: "นาง", code: 2 },
          { title: "นาย", code: 3 }
        ];
      }
    }
  },
  methods: {
    displayJobName(job) {
      if (this.$store.state.lang === "TH") {
        return job.custom_fields?.positionnameth
          ? job.custom_fields.positionnameth
          : job.position_name;
      }
      return job.position_name;
    },
    displayOrganizeName(name) {
      switch (name) {
        case "Head Office":
          return this.$store.state.lang === "EN" ? name : "สำนักงานใหญ่";
        case "Store":
          return this.$store.state.lang === "EN" ? name : "สาขา";
        case "DC":
          return this.$store.state.lang === "EN" ? name : "ศูนย์กระจายสินค้า";
      }

      return "";
    },
    setDetail(val) {
      if (!val) return (this.dfilter = 0);
      if (window.innerWidth > 994) {
        // setTimeout(() => {
        this.dfilter = this.filter;
        // }, 1000);
      } else {
        this.dfilter = this.filter;
      }
    },
    clickMore() {
      $(".list-BG").addClass("h-auto");
      $(".more-BG").remove();
    },
    formCls(field) {
      if (field !== "file") {
        if (field === "email" && this.error && !this.error.email_validate)
          return "is-invalid";
        if (
          field === "phone_number" &&
          this.error &&
          !this.error.phone_validate
        )
          return "is-invalid";
        if (this.error && !this[field]) return "is-invalid";
      } else if (field === "file" && this.error?.resume) {
        return "is-invalid";
      }

      return "";
    },
    formError(field) {
      if (field === "email") {
        if (this.error && this.error.email) return "กรุณากรอกอีเมล";
        if (this.error && !this.error.email_validate)
          return "รูปแบบ E-mail ไม่ถูกต้อง";
      } else if (field === "phone_number") {
        if (this.error && this.error.phone_number)
          return "กรุณากรอกเบอร์โทรศัพท์";
        if (this.error && !this.error.phone_validate)
          return "ข้อมูลหมายเลขโทรศัพท์ไม่ถูกต้อง กรุณากรอกเป็นตัวเลขเท่านั้น";
      }
      return "";
    },
    handleCheck(e) {
      if (this.consent !== "accepted") {
        e.target.checked = false;
      } else {
        e.target.checked = true;
      }
    },
    handleFile(e) {
      this.fileInput = e.target;
      const files = e.target.files;
      if (files[0].size > 5000000) {
        this.alert.message = "The Maximum file size is 5MB";
        $("#error-alert").modal("show");
        return;
      }

      const ext = files[0].name
        .split(".")
        .pop()
        .toLowerCase();
      if (ext !== "pdf" && ext !== "docx" && ext !== "doc") {
        this.alert.message = "Allow only doc, docx, pdf format";
        $("#error-alert").modal("show");
        return;
      }

      let filename = files[0].name;

      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.resume = fileReader.result.split(",").pop();
      });

      fileReader.readAsDataURL(files[0]);
      this.file = files[0];
      this.file_name = files[0].name;
      if (this.error && this.error.resume) {
        this.error.resume = false;
      }
    },
    validate(data) {
      const isEmailValidated = this.validateEmail(data.email);
      const isPhoneValidated = this.validatePhoneNumber(data.phone_number);

      if (
        this.job.area_slug !== "head-office" &&
        data.email &&
        data.full_name.trim() &&
        data.phone_number &&
        isEmailValidated &&
        isPhoneValidated
      )
        return null;

      if (this.job.area_slug === "head-office") {
        if (
          data.email &&
          data.full_name.trim() &&
          data.phone_number &&
          data.resume &&
          isEmailValidated &&
          isPhoneValidated
        )
          return null;
        return {
          email: !data.email,
          email_validate: isEmailValidated,
          full_name: !data.full_name.trim(),
          phone_number: !data.phone_number,
          phone_validate: isPhoneValidated,
          resume: !data.resume
        };
      }
      return {
        email: !data.email,
        email_validate: isEmailValidated,
        full_name: !data.full_name.trim(),
        phone_number: !data.phone_number,
        phone_validate: isPhoneValidated
      };
    },
    onSubmit() {
      if (this.consent !== "accepted") return;
      const isBirthdayValidated = this.validateBirthDate(this.birth_date);
      if (!isBirthdayValidated) {
        this.alert.message = this.source["error_age"];
        $("#error-alert").modal("show");
        return;
      }

      let err = null;
      const data = {
        email: this.email,
        full_name: `${
          this.titleSelected ? `${this.titleSelected.title} ` : ""
        }${this.firstname} ${this.lastname}`,
        phone_number: this.phone_number,
        resume: this.resume
      };

      const errorFields = this.validate(data);

      if (errorFields) {
        this.error = errorFields;
        return;
      }

      const careerData = {
        [this.jobForm["full_name"]]: data.full_name,
        [this.jobForm["email"]]: data.email,
        [this.jobForm["phone_number"]]: data.phone_number,
        [this.jobForm["address"]]: this.address,
        [this.jobForm["birth_date"]]: this.birth_date,
        [this.jobForm["current_salary"]]: this.current_salary,
        [this.jobForm["expected_salary"]]: this.expected_salary,
        [this.jobForm["description"]]: this.description,
        [this.jobForm["consent"]]: true,
        [this.jobForm["firstname"]]: this.firstname,
        [this.jobForm["lastname"]]: this.lastname
      };

      if (this.resume) {
        careerData[this.jobForm["Resume"]] = this.resume;
      }

      if (careerData[this.jobForm["birth_date"]]) {
        const b = moment(this.birth_date);
        if (b.isValid()) {
          careerData[this.jobForm["birth_date"]] = b.format("YYYY-MM-DD");
        } else {
          this.alert.message = "invalid Birth date";
          $("#error-alert").modal("show");
          return;
        }
      }

      const application_data = {};
      for (const key in careerData) {
        const d = careerData[key];
        if (d) {
          application_data[key] = d;
        }
      }

      const loader = this.$loading.show();

      EventService.submitApplicationForm(
        { application_data },
        this.$route.params.id
      )
        .then(() => {
          this.$router.push(
            `/jobs/${this.$route.params.slug}/list/${this.$route.params.id}/success`
          );
        })
        .catch(error => {
          err = error;

          this.alert.message = "ส่งข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง";
          $("#error-alert").modal("show");
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleOk() {
      this.consent = "accepted";
    },
    handleDeleteFile() {
      this.fileInput.value = "";
      this.file = null;
      this.file_name = "";
      this.resume = "";
      if (this.error && this.error.resume) {
        this.error.resume = null;
      }
    },
    validateEmail(mail) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test(mail)) {
        return true;
      }
      return false;
    },
    validatePhoneNumber(phone) {
      if (!phone) return false;
      if (phone.length < 9) return false;
      if (
        phone.length >= 10 &&
        /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
          phone
        )
      )
        return true;
      if (phone.length === 9 && /\d{9}/.test(phone)) return true;

      return false;
    },
    validateBirthDate(date) {
      const birthDate = new Date(date);
      const today = new Date();

      if (today.getFullYear() - birthDate.getFullYear() < 15) {
        console.log(today.getFullYear() - birthDate.getFullYear());
        return false;
      }

      return true;
    }
  },
  created() {
    console.log("job form");
    EventService.getJobById(this.$route.params.id)
      .then(response => {
        this.job = response.data;
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
      });

    EventService.getForm(this.$route.params.id)
      .then(response => {
        const jobForm = response.data.reduce((data, j) => {
          if (j.slug) {
            return {
              ...data,
              [j.slug]: j.id
            };
          } else {
            return {
              ...data,
              [j.label]: j.id
            };
          }
        }, {});
        this.jobForm = jobForm;
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>

<style src="./job_form.scss" scoped lang="scss"></style>
